/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
//@ts-ignore
import { Element } from "react-scroll";
import { HomeSearchContainer } from "../modules/home-search/home-search.container";
import { RecommendedServicesContainer } from "../modules/recommended-services/recommended-services.container";
import { RelevantServicesContainer } from "../modules/relevant-services/relevant-services.container";
import { NewsContainer } from "../modules/news/news.container";
import { AppsContainer } from "../modules/apps/apps.container";
import { CategoryContainer } from "../modules/category/category.container";
import { LinkBarContainer } from "../modules/link-bar/link-bar.container";
import { FooterContainer } from "../modules/footer/footer.container";
import { IndicatorsContainer } from "../modules/indicators/indicators.container";
import { OmbudsmanContainer } from "../modules/ombudsman/ombudsman.container";
import { TopServicesContainer } from "../modules/top-services/top-services.container";
import { Popup } from "../components/popup";
import usePopup from "../hooks/usePopup";
import { getBanner } from "../apis/banner";

export const Home: FC = () => {
  const { open, popup } = usePopup("catalog-home");
  const [banner, setBanner] = useState<any>();

  const startBanner = async () => {
    const data = await getBanner();
    setBanner(data)
    if (data) open()
  };

  useEffect(() => {
    startBanner();
  }, []);

  return (
    <>
      <Popup.Root
        className="rematricula-popup"
        open={popup.isOpen} 
        onOk={popup.close}
        onCancel={popup.close}
        footer={null}
        closeIcon={<span>Fechar [x]</span>}>
        <a
          className="rematricula-popup__content" 
          // eslint-disable-next-line no-script-url
          href={banner?.link || "javascript:void(0);"} 
          target={banner?.link ? "_target" : undefined}
        >
          <img height={580} src={banner?.image} alt={banner?.title} />
        </a>
      </Popup.Root>
  
      <Element name="search">
        <HomeSearchContainer />
      </Element>

      <LinkBarContainer />

      <Element name="recommendedServices">
        <RecommendedServicesContainer />
      </Element>

      <Element name="relevantServices">
        <RelevantServicesContainer />
      </Element>

      <Element name="topServices">
        <TopServicesContainer />
      </Element>

      <Element name="newsContainer">
        <NewsContainer />
      </Element>

      <Element name="appsContainer">
        <AppsContainer />
      </Element>

      <Element name="category">
        <CategoryContainer />
      </Element>

      <Element name="ombudsman">
        <OmbudsmanContainer />
      </Element>

      <Element name="indicators">
        <IndicatorsContainer />
      </Element>

      <FooterContainer />
    </>
  );
};
